<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="defaultMessageUpdating" class="modal-card-title">
              Atualizar Mensagem Padrão
            </p>
            <p v-else class="modal-card-title">Inserir Mensagem Padrão</p>
          </header>
          <section class="modal-card-body" style="overflow-y: auto !important">
            <b-field label="Título">
              <b-input
                type="text"
                v-model="title"
                placeholder="Título"
                required
              >
              </b-input>
            </b-field>
            <b-message class="warning-message" type="is-warning">
              As mensagens predefinidas suportam variáveis que serão trocadas pelos dados do cliente: Utilize <b>{nome}</b> e será trocado pelo nome do contato, utilize <b>{telefone}</b> e será trocado pelo número de telefone do contato e utilize <b>{ticket}</b> e será trocado pelo código do ticket atual do contato. Atenção que para a troca correta, o número já deve estar adicionado no sistema como contato.
            </b-message>
            <b-field label="Mensagem">
              <editor
                api-key="no-api-key"
                v-model="message"
                output-format="text"
                required
                :init="{
                  height: 200,
                  menubar: false,
                  plugins: ['emoticons'],
                  toolbar: 'emoticons',
                  branding: false,
                  statusbar: false,
                }"
              />
            </b-field>
            <b-field>
              <b-upload v-model="dropFile" drag-drop expanded>
                <section class="section is-fullwidth">
                  <div class="content has-text-centered is-fullwidth">
                    <p>
                      <b-icon icon="upload" size="is-medium"> </b-icon>
                    </p>
                    <p>Solte o arquivo aqui ou Clique para selecionar</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="tags">
              <span v-if="dropFile !== null" class="tag is-primary">
                {{ dropFile.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="deleteDropFile"
                ></button>
              </span>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button
              v-if="!!defaultMessageUpdating"
              class="button is-primary"
              @click="updateDefaultMessage"
            >
              Atualizar
            </button>
            <button v-else class="button is-primary" @click="addDefaultMessage">
              Gravar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'ModalInsertDefaultMessage',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    defaultMessageUpdating: {
      type: Object,
      required: false
    }
  },
  components: {
    editor: Editor
  },
  data () {
    return {
      message: '',
      dropFile: null,
      fileBase64: null,
      newFile: null,
      title: '',
      hasUpdatedFile: false
    }
  },
  created () {
    this.loadDataAsync()
  },
  watch: {
    dropFile (newFile, oldFile) {
      // Not considering the first update of the file
      if (!oldFile && !!newFile && !!this.defaultMessageUpdating && newFile.name === this.defaultMessageUpdating.file_name) return;
      this.hasUpdatedFile = true
    }
  },
  methods: {
    async updateDefaultMessage () {
      if (this.hasUpdatedFile && this.dropFile !== null) {
        const base64 = await this.blobToBase64(this.dropFile)
        const fileConverted = base64.substr(base64.indexOf(',') + 1)
        this.fileBase64 = {
          base64: fileConverted,
          file: this.dropFile
        }
      }
      if (this.title === '') {
        this.$buefy.dialog.alert({
          title: 'Insira o Título da Mensagem',
          message: 'Insira o título da mensagem antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {
        this.emitUpdateDefaultMessage({
          id: this.defaultMessageUpdating.id,
          message: this.message !== '' ? this.message : null,
          title: this.title,
          file_base64: this.fileBase64 !== null ? this.fileBase64.base64 : null,
          file_mimetype:
            this.fileBase64 !== null ? this.fileBase64.file.type : null,
          file_name:
            this.fileBase64 !== null ? this.fileBase64.file.name : null,
          has_updated_file: this.hasUpdatedFile
        })
      }
    },
    emitUpdateDefaultMessage (defaultMessage) {
      this.$emit('updateDefaultMessage', defaultMessage)
    },
    async loadDataAsync () {
      if (this.defaultMessageUpdating) {
        this.message = this.defaultMessageUpdating.message
        this.title = this.defaultMessageUpdating.title
        if (this.defaultMessageUpdating.file_name !== null) {
          this.dropFile = {
            name: this.defaultMessageUpdating.file_name,
            type: this.defaultMessageUpdating.file_mimetype
          }
        }
      }
    },
    async addDefaultMessage () {
      if (this.hasUpdatedFile && this.dropFile !== null) {
        const base64 = await this.blobToBase64(this.dropFile)
        const fileConverted = base64.substr(base64.indexOf(',') + 1)
        this.fileBase64 = {
          base64: fileConverted,
          file: this.dropFile
        }
      }
      if (this.title === '') {
        this.$buefy.dialog.alert({
          title: 'Insira o Título da Mensagem',
          message: 'Insira o título da mensagem antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {
        this.$emit('addDefaultMessage', {
          message: this.message !== '' ? this.message : null,
          title: this.title,
          file_base64: this.fileBase64 !== null ? this.fileBase64.base64 : null,
          file_mimetype:
            this.fileBase64 !== null ? this.fileBase64.file.type : null,
          file_name:
            this.fileBase64 !== null ? this.fileBase64.file.name : null,
          has_updated_file: this.hasUpdatedFile
        })
      }
    },
    closeModal () {
      this.$emit('closeModal')
    },
    deleteDropFile () {
      this.hasUpdatedFile = true
      this.dropFile = null
    },
    blobToBase64 (blob) {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    },
    async base64ToBlob (filebase64) {
      const response = await fetch(
        `data:${filebase64.fileType};base64,${filebase64.base64}`
      )
      const blob = await response.blob()
      return new File([blob], filebase64.fileName, {
        type: filebase64.fileType
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

.emoji-picker-container {
  white-space: pre-wrap;
}
.multiselect--active {
  z-index: 999;
}
</style>
