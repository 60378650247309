<template>
  <div>
    <div v-if="allDefaultMessages.length > 0">
        <b-table
          :data="allDefaultMessages"
          :bordered="false"
          :striped="true"
          :narrowed="false"
          :hoverable="true"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
          :paginated="true"
          scrollable
        >

          <!-- <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
              {{ props.row.id }}
          </b-table-column> -->

          <b-table-column field="title" label="Título" v-slot="props">
              {{ props.row.title }}
          </b-table-column>

          <b-table-column field="file" label="Tem Arquivo?" v-slot="props">
              {{ props.row.file_name !== null ? 'Sim' : 'Não' }}
          </b-table-column>

          <b-table-column field="createdAt" width="200" label="Criado em" v-slot="props">
              {{
                  props.row.created_at
                  ? formatDateTime(props.row.created_at)
                  : ""
              }}
          </b-table-column>

          <b-table-column field="update_delete" label="" v-slot="props">
            <dropdown-tag :isDeleting="defaultMessageDeletingId === props.row.id">
              <dropdown-item-tag @click="updateDefaultMessage(props.row)">Atualizar</dropdown-item-tag>
              <dropdown-item-tag @click="confirmDefaultMessageDelete(props.row)">Excluir</dropdown-item-tag>
            </dropdown-tag>
          </b-table-column>

          <template #footer>
              <div class="has-text-left">
                  Quantidade de registros {{ allDefaultMessages.length }}
              </div>
          </template>
        </b-table>
    </div>
  </div>
</template>

<script>

import mixin from '../../utils/mixins'
import DropdownTag from '../commons/DropdownTag.vue'
import DropdownItemTag from '../commons/DropdownItemTag.vue'

export default {
  name: 'DefaultMessagesList',
  props: {
    defaultMessages: {
      type: Array,
      required: true
    },
    defaultMessageDeletingId: {
      type: Number,
      required: true
    }
  },
  mixins: [mixin],
  components: {
    DropdownTag,
    DropdownItemTag
  },
  computed: {
    allDefaultMessages: {
      get () {
        return this.defaultMessages
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    confirmDefaultMessageDelete (defaultMessage) {
      this.$buefy.dialog.confirm({
        title: 'Excluir mensagem',
        message: 'Deseja <b>excluir</b> esta mensagem?',
        confirmText: 'Excluir Mensagem',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removeDefaultMessage', defaultMessage)
      })
    },
    updateDefaultMessage (defaultMessage) {
      this.$emit('updateDefaultMessage', defaultMessage)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
