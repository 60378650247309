<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Mensagens predefinidas
    </hero-bar>
    <section class="section is-main-section">
        <ModalInsertDefaultMessage
            v-if="isComponentModalActive"
            v-on:addDefaultMessage="handleAddDefaultMessage"
            v-on:closeModal="closeModal"
            :isComponentModalActive="isComponentModalActive"
            v-on:updateDefaultMessage="handleUpdateDefaultMessage"
            :defaultMessageUpdating="defaultMessageUpdating"
        />
      <b-loading v-if="fetchingDefaultMessages" :is-full-page="true" v-model="fetchingDefaultMessages" :can-cancel="false"></b-loading>
      <DefaultMessagesList v-else :defaultMessages='allDefaultMessages' v-on:removeDefaultMessage="removeDefaultMessage" v-on:updateDefaultMessage="OpenUpdateDefaultMessage" :defaultMessageDeletingId="defaultMessageDeletingId" />
      <FabButton v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Nova mensagem predefinida" />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import FabButton from '@/components/FabButton.vue'
import ModalInsertDefaultMessage from '@/components/modals/ModalInsertDefaultMessage'
import DefaultMessagesList from '@/components/default-messages/DefaultMessagesList'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MenuOptionsScreen',
  components: {
    HeroBar,
    TitleBar,
    FabButton,
    ModalInsertDefaultMessage,
    DefaultMessagesList
  },
  data () {
    return {
      isComponentModalActive: false,
      defaultMessageUpdating: null,
      defaultMessageDeletingId: 0
    }
  },
  computed: {
    ...mapGetters(['allDefaultMessages', 'fetchingDefaultMessages']),
    titleStack () {
      return ['Cadastros', 'Mensagens predefinidas']
    }
  },
  methods: {
    ...mapActions(['addDefaultMessage', 'fetchDefaultMessages', 'fetchDefaultMessagesWithoutFile', 'deleteDefaultMessages', 'updateDefaultMessage']),
    handleUpdateDefaultMessage (defaultMessage) {
      this.closeModal()
      this.updateDefaultMessage(defaultMessage).catch(() =>
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'Ocorreu um <b>erro</b> ao atualizar a mensagem. Recarregue a tela e tente novamente!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
    },
    handleAddDefaultMessage (defaultMessage) {
      this.closeModal()
      this.addDefaultMessage(defaultMessage).catch(() => {
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'Ocorreu um <b>erro</b> ao inserir a mensagem. Recarregue a tela e tente novamente!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
    },
    OpenUpdateDefaultMessage (defaultMessage) {
      this.defaultMessageUpdating = defaultMessage
      this.isComponentModalActive = !this.isComponentModalActive
    },
    handleAddClick () {
      this.defaultMessageUpdating = null
      this.isComponentModalActive = !this.isComponentModalActive
    },
    async removeDefaultMessage (defaultMessage) {
      try {
        this.defaultMessageDeletingId = defaultMessage.id
        await this.deleteDefaultMessages(defaultMessage.id)
        this.$buefy.toast.open('Mensagem predefinida excluída com sucesso!')
      } catch (error) {
        this.$buefy.toast.open('Não foi possível excluir a mensagem predefinida!')
      } finally {
        this.defaultMessageDeletingId = 0
      }
    },
    closeModal () {
      this.isComponentModalActive = false
    }
  },
  created () {
    this.fetchDefaultMessagesWithoutFile()
  }
}
</script>
