var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('b-modal',{attrs:{"active":_vm.isComponentModalActive},on:{"close":_vm.closeModal}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[(_vm.defaultMessageUpdating)?_c('p',{staticClass:"modal-card-title"},[_vm._v(" Atualizar Mensagem Padrão ")]):_c('p',{staticClass:"modal-card-title"},[_vm._v("Inserir Mensagem Padrão")])]),_c('section',{staticClass:"modal-card-body",staticStyle:{"overflow-y":"auto !important"}},[_c('b-field',{attrs:{"label":"Título"}},[_c('b-input',{attrs:{"type":"text","placeholder":"Título","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('b-message',{staticClass:"warning-message",attrs:{"type":"is-warning"}},[_vm._v(" As mensagens predefinidas suportam variáveis que serão trocadas pelos dados do cliente: Utilize "),_c('b',[_vm._v("{nome}")]),_vm._v(" e será trocado pelo nome do contato, utilize "),_c('b',[_vm._v("{telefone}")]),_vm._v(" e será trocado pelo número de telefone do contato e utilize "),_c('b',[_vm._v("{ticket}")]),_vm._v(" e será trocado pelo código do ticket atual do contato. Atenção que para a troca correta, o número já deve estar adicionado no sistema como contato. ")]),_c('b-field',{attrs:{"label":"Mensagem"}},[_c('editor',{attrs:{"api-key":"no-api-key","output-format":"text","required":"","init":{
                height: 200,
                menubar: false,
                plugins: ['emoticons'],
                toolbar: 'emoticons',
                branding: false,
                statusbar: false,
              }},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('b-field',[_c('b-upload',{attrs:{"drag-drop":"","expanded":""},model:{value:(_vm.dropFile),callback:function ($$v) {_vm.dropFile=$$v},expression:"dropFile"}},[_c('section',{staticClass:"section is-fullwidth"},[_c('div',{staticClass:"content has-text-centered is-fullwidth"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-medium"}})],1),_c('p',[_vm._v("Solte o arquivo aqui ou Clique para selecionar")])])])])],1),_c('div',{staticClass:"tags"},[(_vm.dropFile !== null)?_c('span',{staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(_vm.dropFile.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":_vm.deleteDropFile}})]):_vm._e()])],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" Fechar ")]),(!!_vm.defaultMessageUpdating)?_c('button',{staticClass:"button is-primary",on:{"click":_vm.updateDefaultMessage}},[_vm._v(" Atualizar ")]):_c('button',{staticClass:"button is-primary",on:{"click":_vm.addDefaultMessage}},[_vm._v(" Gravar ")])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }